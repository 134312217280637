import React, { useState, useEffect, useRef } from "react";
import { useGlobal } from "reactn";
import { useHistory } from "react-router-dom";
import SummonerFinder from "components/SummonerFinder";
import ButtonSuccess from "components/Buttons/ButtonSuccess";
import { ContentSidePadding } from "components/ContentSidePadding";
import { useQuery, useMutation } from "@apollo/client";
import {
  useSummonerProfileInitSimple,
  useManualSummonerProfileInitSimple,
} from "@ugg/shared/api/requests/summoner-profiles/profile-init";
import { GET_USER_SETTINGS, UPDATE_SETTINGS } from "@ugg/shared/api/graphql/queries";
import { GET_USER_STATE } from "@ugg/shared/api/requests/accounts/user-state";
import { useLoginState } from "lib/hooks/userHooks";
import { splitRiotId } from "@ugg/shared/utils/riot-id-helpers";
import { getProfileOverviewUrl } from "@ugg/shared/routes/app-routes";
import { ReactComponent as SearchIconGrey } from "svg/magnifying-glass-searchbar.svg";
// Page flow:
// On initial load, if not logged in, redirect to home page
// On inital load, if logged in AND has valid summoner profile, redirect to user summoner profile
// on Initial load, if logged in AND no valid summoner profile, stay on this page

const NotFoundUserSummonerProfile = (props) => {
  const inputRef = useRef();
  const history = useHistory();
  const [clientRegion] = useGlobal("clientRegion");
  const [loggingIn] = useGlobal("loggingIn");
  const isLoggedIn = useLoginState();

  const {
    loading: loadingUserState,
    data: userState,
    error: errorUserState,
  } = useQuery(GET_USER_STATE, { fetchPolicy: "network-only" });

  const { getState } = userState || {};
  const { lolSettings } = getState || {};
  const { summoners } = lolSettings || {};
  const { riotUserName, riotTagLine, regionId } = (summoners && summoners[0]) || {};
  const [inputRiotId, setInputRiotId] = useState(riotUserName && riotTagLine ? `${riotUserName} #${riotTagLine}` : "");
  const [inputRegionId, setRegion] = useState(clientRegion);
  const [inputRiotUserName, inputRiotTagLine] = splitRiotId(inputRiotId);

  const [updateSettings, { loading: loadingUpdate, data: updateData, error: errorUpdate }] = useMutation(UPDATE_SETTINGS, {
    refetchQueries: [{ query: GET_USER_STATE }, { query: GET_USER_SETTINGS }],
    variables: {
      primarySummoner: {
        riotUserName: inputRiotUserName,
        riotTagLine: inputRiotTagLine,
        regionId: inputRegionId,
      },
    },
  });

  const {
    loading: loadingSummonerProfile,
    data: summonerProfile,
    error: errorSummonerProfile,
  } = useSummonerProfileInitSimple(regionId, riotUserName, riotTagLine, {}, { skip: !userState });

  const [verifySummoner, { loading: loadingVerification, data: dataVerification, error: errorVerification }] =
    useManualSummonerProfileInitSimple(inputRegionId, inputRiotUserName, inputRiotTagLine, {}, "");

  const onEnter = (e) => {
    if (e.which === 13) {
      verifySummoner();
    }
  };

  useEffect(() => {
    setRegion(regionId);
  }, [regionId]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener("keypress", onEnter);

      return () => inputRef && inputRef.current && inputRef.current.removeEventListener("keypress", onEnter);
    }
  }, [loggingIn, loadingUserState, loadingSummonerProfile, updateData, inputRiotId, inputRegionId]);

  useEffect(() => {
    // If new summoner name exists, update user account
    if (dataVerification) {
      updateSettings();
    }
  }, [dataVerification]);

  // On initial load, if not logged in, redirect to home page
  useEffect(() => {
    if (!loggingIn && !isLoggedIn) {
      history.replace("/");
    } else if (summonerProfile) {
      history.replace(getProfileOverviewUrl(regionId, riotUserName, riotTagLine));
    }
  }, [loggingIn, isLoggedIn, updateData, summonerProfile]);

  if ((loggingIn || loadingUserState || loadingSummonerProfile) && !updateData) {
    return (
      <div className="not-found-user-summoner-profile not-found_loading">
        <div className="spinthatshit-loader">
          <div className="spinner"></div>
        </div>
      </div>
    );
  }

  return (
    <ContentSidePadding>
      <div className="not-found-user-summoner-profile">
        <div className="not-found_content">
          <img className="rengar-img" src="https://static.bigbrain.gg/assets/lol/art/rengar.png" />
          <div className="header">We can't find your summoner profile</div>
          <div className="sub-text">Did you make a typo or select the wrong region?</div>
          <div className="summoner-name-input-container">
            <SummonerFinder
              inputRef={inputRef}
              autoFocus={true}
              placeholder={"Summoner Name"}
              riotId={inputRiotId}
              summonerRegion={inputRegionId}
              onSummonerChange={(e) => setInputRiotId(e.target.value)}
              onRegionChange={(region) => setRegion(region)}
              dontDefaultUniversalRegion
              color="white"
            />
            <ButtonSuccess
              className="save-settings-btn btn-blue max-xs:hidden"
              label="Save"
              disable={inputRiotId.trim() === ""}
              isLoading={loadingUpdate || loadingUserState || loadingSummonerProfile || loadingVerification}
              onClick={verifySummoner}
            />
            <div className="xs:hidden absolute right-0 p-[12px]" onClick={verifySummoner}>
              <SearchIconGrey className="w-[24px]" />
            </div>
          </div>
          {!loadingVerification && errorVerification && (
            <div className="error-dialogue-box">
              We were unable to find your profile, please contact{" "}
              <a className="underline" href="mailto:info@u.gg">
                info@u.gg
              </a>{" "}
              for additional support.
            </div>
          )}
        </div>
      </div>
    </ContentSidePadding>
  );
};

export default NotFoundUserSummonerProfile;
