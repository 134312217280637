import classNames from "classnames";
import { Helmet } from "react-helmet-async";
import dark404Img from "@ugg/shared/assets/images/404-dark.png";

interface Error404Props {
  className?: string;
  metaTitle?: string;
}

export function Error404(props: Error404Props) {
  const { className, metaTitle } = props;

  const defaultMetaTitle = "U.GG - Page Not Found";

  return (
    <div
      className={classNames(
        {
          "mx-auto w-full max-w-[1014px] py-[36px] max-sm:py-[24px] content-side-padding":
            process.env.UGG_PUBLIC_PLATFORM !== "app",
          "w-[var(--main-col-width)] py-[36px]": process.env.UGG_PUBLIC_PLATFORM === "app",
        },
        className,
      )}
    >
      <Helmet>
        <title>{metaTitle || defaultMetaTitle}</title>
      </Helmet>
      <div className="flex flex-wrap-reverse justify-center gap-[24px]">
        <div className="flex-1 basis-[400px] text-[48px] max-sm:text-[36px]">
          <div className="text-[1em] font-bold">
            <div>THIS PAGE</div>
            <div>DOESN'T EXIST</div>
          </div>
          <div className="mt-[30px] text-lavender-50">
            <span className="text-[24px]">Just like your team's map awareness.</span>
          </div>
          <hr className="my-[50px] max-sm:my-[24px] border-purple-400" />
          <div className="text-[0.45em]">The page you're looking for isn't available.</div>
          <div className="text-[0.45em]">Try to search again.</div>
        </div>
        <div className="flex-1 basis-[400px]">
          <img className="mx-auto w-full max-w-[500px]" src={dark404Img} alt="404" />
        </div>
      </div>
    </div>
  );
}
