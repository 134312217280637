import * as React from "react";
import type { SVGProps } from "react";
export const SvgGameMarvelRivals = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      fill="#EBC511"
      d="M9.29396 3.99078H1.21979L9.98909 8.37723L3.35864 8.26892L8.59883 14.0092H16.8869L8.27801 9.73107H14.5876L9.29396 3.99078Z"
    />
  </svg>
);
