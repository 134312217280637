import React, { useEffect } from "react";
import { useGlobal } from "reactn";
import { useQuery } from "@apollo/client";
import { Switch, Redirect, Route, useLocation } from "react-router-dom";
import classnames from "classnames";
import loadable from "@loadable/component";
import Footer from "@ugitgud/legos/ui/footer/Footer";
import { RouteStatus } from "@ugg/shared/components/common/RouteStatus";
import { GET_USER_SETTINGS } from "@ugg/shared/api/graphql/queries";
import { AppRoutes, RouterPaths } from "@ugg/shared/routes/app-routes";
import { RiotIdRedirect } from "@ugg/shared/components/SummonerProfiles/RiotIdRedirect";
import { useUserPremiumState } from "@ugg/shared/api/requests/accounts/user-state";
import DisabledRoute from "components/DisabledRoute";
import PremiumFAQ from "components/Accounts/PremiumFAQ";
import OpenSearchRedirect from "components/OpenSearchRedirect";
import { Error404 } from "@ugg/shared/components/common/Error404";
import AdWhitelist from "components/Ads/AdWhitelist";
import Login from "components/Accounts/Login";
import Signup from "components/Accounts/Signup";
import VerificationContainer from "components/Pages/VerificationPage/views/VerificationContainer";
import NotFoundUserSummonerProfile from "components/Accounts/NotFoundUserSummonerProfile/views/NotFoundUserSummonerProfile";

// Loadable Components
const LandingPageContainer = loadable(() => import("components/Pages/LandingPage/LandingPageContainer"));
const DesktopAppSplashPage = loadable(() => import("components/DesktopAppSplashPage/DesktopAppSplashPage"));
const SupportUsPage = loadable(() => import("components/Pages/SupportUs/SupportUsPage"));
const LeaderboardsPage = loadable(() => import("components/Leaderboards/LeaderboardsPage"), {
  resolveComponent: (components) => components.LeaderboardsPage,
});
const TierListPage = loadable(() => import("components/TierListPage"), {
  resolveComponent: (components) => components.TierListPage,
});
const ChampionsRoutes = loadable(() => import("components/ChampionsRoutes"), {
  resolveComponent: (components) => components.ChampionsRoutes,
});
const ChampionsPage = loadable(() => import("components/Champions/ChampionsPage"), {
  resolveComponent: (components) => components.ChampionsPage,
});
const SummonerProfile = loadable(() => import("components/SummonerProfiles/SummonerProfile"), {
  resolveComponent: (components) => components.SummonerProfile,
});
const Settings = loadable(() => import("components/Accounts/Settings/Settings"));
const RecoverAccount = loadable(() => import("components/Accounts/RecoverAccount/RecoverAccount"));
const PasswordReset = loadable(() => import("components/Accounts/PasswordReset/PasswordReset"));
const FAQ = loadable(() => import("components/Pages/FAQ"));
const PrivacyPolicy = loadable(() => import("components/PrivacyPolicy"));
const PrivacyShield = loadable(() => import("components/PrivacyShield"));
const TermsOfService = loadable(() => import("components/TermsOfService"));
const PremiumCheckout = loadable(() => import("components/Premium/views/PremiumCheckout"));
const LiveGameSplashContainer = loadable(() => import("components/SummonerProfiles/LiveGame/LiveGameSplash"), {
  resolveComponent: (components) => components.LiveGameSplashContainer,
});
const EmailVerificationPage = loadable(() => import("components/Pages/EmailVerificationPage/EmailVerificationPage"));
const ItemsPage = loadable(() => import("components/Items/ItemsPage"), {
  resolveComponent: (components) => components.ItemsPage,
});
const ItemsDirectory = loadable(() => import("components/Items/ItemsDirectory"), {
  resolveComponent: (components) => components.ItemsDirectory,
});
const MultiSearchMain = loadable(() => import("components/MultiSearch/MultiSearchMain"));

const MainContent = (props) => {
  const [expandedSideNav] = useGlobal("expandedSideNav");
  const [verificationBar] = useGlobal("verificationBar");
  const [authToken] = useGlobal("authToken");
  const location = useLocation();

  const { data: userSettings } = useQuery(GET_USER_SETTINGS, { fetchPolicy: "network-only", skip: !authToken });
  const [loggingIn] = useGlobal("loggingIn");
  const { data, loading } = useUserPremiumState();
  const isAdFree = loading || loggingIn || data?.isPremium;

  useEffect(() => {
    if (userSettings) {
      if (!window?.ramp) {
        window.ramp = window.ramp || { que: [] };
        window.ramp.que.push(() => {
          window.ramp.setUserProfile({
            eid: window.btoa(userSettings?.getSettings?.email),
            format: "EUP1",
          });
        });
      } else {
        window.ramp.up = {
          format: "EUP1",
          eid: window.btoa(userSettings?.getSettings?.email),
        };
      }
    }
  }, [userSettings]);

  useEffect(() => {
    if (!isAdFree && window.admiral) {
      console.log("Resume Admiral Scripts");
      window.admiral("targeting", "ready");
    }
  }, [isAdFree]);

  const mainContentClassNames = classnames({
    "verification-bar-active": verificationBar,
    collapsed: !expandedSideNav,
    expanded: expandedSideNav,
  });

  const disclaimerText =
    "U.GG Stats isn't endorsed by Riot Games and doesn't reflect the views or opinions of Riot Games or anyone officially involved in producing or managing League of Legends.";

  return (
    <div id="main-content" className={mainContentClassNames} ref={props.forwardedRef}>
      <div id="content-wrapper">
        <div id="content" style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Switch>
            <Route exact path="/" component={LandingPageContainer} />
            <Route exact path="/app" component={DesktopAppSplashPage} />
            <Route exact path="/support-us" component={SupportUsPage} />
            <Redirect from="/discover-boost" to="/support-us" />
            <Route exact path={AppRoutes.LIVE_GAMES} component={LiveGameSplashContainer} />
            <Route path="/verify" component={EmailVerificationPage} />
            <Route path="/not-found-user-summoner-profile" component={NotFoundUserSummonerProfile} />
            <Route path={AppRoutes.PROFILE_ROOT} component={SummonerProfile} />
            <Route
              path={AppRoutes.PROFILE_ROOT_OLD}
              component={RiotIdRedirect}
              /* Must come after AppRoutes.PROFILE_ROOT */
            />
            <Route path={RouterPaths.TIER_LIST} component={TierListPage} />
            <Route path={AppRoutes.CHAMPION_HOME} exact component={ChampionsPage} />
            <Route path={AppRoutes.CHAMPION_LEADERBOARD} component={ChampionsPage} />
            <Route path={AppRoutes.CHAMPION_ROOT} component={ChampionsRoutes} />
            <Route path={AppRoutes.ITEM_WIKI} component={ItemsPage} />
            <Route path={AppRoutes.ITEMS_DIRECTORY} component={ItemsDirectory} />
            {/* Leaderboards */}
            <Route path={AppRoutes.LEADERBOARD} component={LeaderboardsPage} />
            <Redirect path={AppRoutes.OLD_LEADERBOARD} to={AppRoutes.LEADERBOARD} />
            {/*  */}
            <Route path="/terms-of-service" render={(props) => <TermsOfService {...props} />} />
            <Route path={AppRoutes.ADBLOCK_WHITELIST} render={(props) => <AdWhitelist {...props} />} />
            <Route path="/privacy-policy" render={(props) => <PrivacyPolicy {...props} />} />
            <Route path="/privacy-shield" render={(props) => <PrivacyShield {...props} />} />
            <Route path="/opensearch" render={(props) => <OpenSearchRedirect {...props} />} />
            <Route path={AppRoutes.MULTISEARCH} render={(props) => <MultiSearchMain {...props} />} />
            <DisabledRoute path="/settings" component={Settings} />
            <Route path="/recover-account" component={RecoverAccount} />
            <Route path="/reset-password" component={PasswordReset} />
            <Route path="/faq" render={(props) => <FAQ {...props} />} />
            <Route path="/login" render={(props) => <Login {...props} />} />
            <Route path="/signup" render={(props) => <Signup {...props} />} />
            <DisabledRoute path="/boost/payment" component={PremiumCheckout} />
            <Redirect from="/boost(/*)?" to="/boost/payment" />
            <Route path="/boost-faq" render={(props) => <PremiumFAQ {...props} />} />
            <Route path="/verification" render={(props) => <VerificationContainer />} />
            <RouteStatus statusCode={404} path="*">
              <Error404 />
            </RouteStatus>
          </Switch>
        </div>
        <div className="content-side-padding">
          <Footer disclaimerText={disclaimerText} path={location.pathname} />
        </div>
      </div>
    </div>
  );
};

export default MainContent;
