import React, { MouseEventHandler } from "react";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { IconSizes } from "@ugitgud/legos/ui/shared";
import { ValorantIconName } from "@ugitgud/legos/ui/icon/raster/valorant/ValorantIconTypes";
import { getValorantIconURL } from "@ugitgud/legos/ui/icon/raster/valorant/ValorantIcon";
import Image, { ImageProps, StaticImageData } from "next/image";
import sizes from "@ugitgud/legos/plugins/sizes.json";

export type IconName = ValorantIconName;

export type IconProps = {
  className?: string;
  icon: React.ReactSVGElement | ValorantIconName | JSX.Element | React.ReactNode;
  label?: string;
  size?: IconSizes;
  width?: number | string;
  height?: number | string;
  mono?: boolean;
  wide?: boolean;
  onClick?: MouseEventHandler<HTMLSpanElement>;
};

const isStaticImageData = (url: ImageProps["src"]): url is StaticImageData => {
  return (url as StaticImageData)?.src !== undefined;
};

// note(jsimms): since nextjs loads images with a custom loader we have to type guard this
const resolveUrl = (url: string | ImageProps["src"] | null | undefined) => {
  if (url === null || url === undefined) {
    return undefined;
  }
  if (typeof url === "string") {
    return url;
  }
  if (isStaticImageData(url)) {
    return url.src;
  }
  return url.default.src;
};

const resolveSize = (
  size: IconProps["size"],
  url?: string | ImageProps["src"],
  wide?: boolean,
  width?: number | string,
  height?: number | string,
): [number | string, number | string] => {
  if (width && height) {
    return [width, height];
  }
  if (size !== undefined) {
    if (wide) {
      return [parseInt(sizes.iconSizes[size], 10) * 5, sizes.iconSizes[size]];
    }
    return [sizes.iconSizes[size], sizes.iconSizes[size]];
  }
  if (url !== undefined && typeof url !== "string") {
    return isStaticImageData(url) ? [url.width, url.height] : [url.default.width, url.default.height];
  }
  return [0, 0];
};

export const Icon: React.FC<IconProps> = ({ icon, mono: fill, wide, className, size = "sm", onClick, label, width, height }) => {
  const isNamedIcon = typeof icon === "string";
  const iconUrl = getValorantIconURL(icon as ValorantIconName) as string | ImageProps["src"] | undefined;
  const url = isNamedIcon ? resolveUrl(iconUrl) : undefined;
  const iconSize = resolveSize(size, iconUrl, fill, width, height);
  return (
    <span
      role="img"
      aria-label={label}
      style={
        fill ? { maskImage: isNamedIcon ? `url('${url}')` : undefined, maskSize: "contain", maskRepeat: "no-repeat" } : undefined
      }
      className={twMerge(
        classNames("flex-shrink-0 icon inline-flex items-start justify-start", `icon-${size.toString()}`, className, {
          "icon-mono": fill,
          wide,
        }),
      )}
      onClick={onClick}
    >
      {!isNamedIcon && icon}
      {isNamedIcon && !fill && url && (
        <>
          {typeof iconUrl === "string" ? (
            <img src={url} />
          ) : (
            <Image width={iconSize[0]} height={iconSize[1]} className="h-full w-auto" src={url} alt={icon} />
          )}
        </>
      )}
    </span>
  );
};
