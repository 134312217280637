import { compile } from "path-to-regexp";
import { RoleS, normalizeRole } from "@ugg/shared/utils/role-helpers";
import { RegionS, convertLeagueClientRegion } from "@ugg/shared/utils/region-helpers";
import { buildQueryParams } from "@ugg/shared/utils/url-query-params";

export const AppRoutes = {
  // Normal Pages
  HOME: "/",
  // Account
  LOGIN: "/login",
  SIGNUP: "/signup",
  RECOVER_ACCOUNT: "/recover-account",
  RESET_PASSWORD: "/reset-password",
  VERIFICATION: "/verification",
  SETTINGS: "/settings",
  SETTINGS_ACCOUNT: "/settings/my-account",
  SETTINGS_FILTER_PREFERENCES: "/settings/filter-preferences",
  SETTINGS_PREMIUM: "/settings/boost",
  SETTINGS_NOTIFICATIONS: "/settings/notifications",

  // Profiles
  PROFILE_ROOT_OLD: "/lol/profile/:region/:summonerName",
  PROFILE_ROOT: "/lol/profile/:region/:riotUserName-:riotTagLine",
  PROFILE_OVERVIEW: "/lol/profile/:region/:riotUserName-:riotTagLine/overview",
  PROFILE_CHAMPION_STATS: "/lol/profile/:region/:riotUserName-:riotTagLine/champion-stats",
  PROFILE_LIVE_GAME: "/lol/profile/:region/:riotUserName-:riotTagLine/live-game",
  PROFILE_LOADER: "/lol/profile/:region/:riotUserName-:riotTagLine/loader",
  PROFILE_LOADING_MATCH_PAGE: "/lol/profile/:region/:riotUserName-:riotTagLine/loadingMatch/:patch/:matchId",
  PROFILE_MATCH_PAGE: "/lol/profile/:region/:riotUserName-:riotTagLine/match/:patch/:matchId",
  PROFILE_MATCH_PAGE_FORCE: "/lol/profile/:region/:riotUserName-:riotTagLine/forceMatch/:patch/:matchId",
  PROFILE_HIGHLIGHTS: "/lol/profile/:region/:riotUserName-:riotTagLine/highlights",
  // Multisearch
  MULTISEARCH: "/lol/multisearch",
  // Leaderboards
  OLD_LEADERBOARD: "/leaderboards",
  LEADERBOARD: "/lol/leaderboards",
  OLD_LEADERBOARD_RANKING: "/leaderboards/ranking",
  LEADERBOARD_RANKING: "/lol/leaderboards/ranking",
  CHAMPION_LEADERBOARD: "/lol/champion-leaderboards/:champion?",
  // Live Games Home
  LIVE_GAMES: "/lol/lg-splash",
  // Items
  ITEMS_DIRECTORY: "/lol/items",
  ITEM_WIKI: "/lol/items/:itemName",

  // Tier Lists
  DEFAULT_TIER_LIST: "/lol/tier-list",
  TOP_TIER_LIST: "/lol/top-lane-tier-list",
  JUNGLE_TIER_LIST: "/lol/jungle-tier-list",
  MID_TIER_LIST: "/lol/mid-lane-tier-list",
  ADC_TIER_LIST: "/lol/adc-tier-list",
  SUPPORT_TIER_LIST: "/lol/support-tier-list",
  SPECIAL_GAME_MODE_TIER_LIST: "/lol/:gameMode-tier-list",
  // URF_TIER_LIST: `/lol/urf-tier-list`,
  PICK_URF_TIER_LIST: `/lol/urf-tier-list`,
  ARURF_TIER_LIST: `/lol/arurf-tier-list`,
  ARAM_TIER_LIST: `/lol/aram-tier-list`,
  NEXUS_BLITZ_TIER_LIST: `/lol/nexus-blitz-tier-list`,
  ONE_FOR_ALL_TIER_LIST: `/lol/one-for-all-tier-list`,
  ARENA_TIER_LIST: `/lol/arena-tier-list`,
  ARENA_DUO_TIER_LIST: `/lol/arena-duo-tier-list`,
  DUO_TIER_LIST: "/lol/duo-tier-list",
  COMBAT_TIER_LIST: "/lol/combat",
  OBJECTIVES_TIER_LIST: "/lol/objectives",

  // Champions
  CHAMPION_ROOT: "/lol/:language?/champions",
  CHAMPION_HOME: "/lol/champions",
  CHAMPION_PROFILE_ROOT: "/lol/:language?/champions/:champion",
  CHAMPION_PROFILE_BUILD: "/lol/:language?/champions/:multi?/:champion/build/:role?",
  CHAMPION_PROFILE_BUILD_SPECIAL_GAME_MODE: "/lol/:language?/champions/:gameMode/:multi?/:champion-:gameMode2",
  CHAMPION_PROFILE_BUILD_ARAM: "/lol/champions/aram/:multi?/:champion-aram",
  CHAMPION_PROFILE_BUILD_URF: "/lol/champions/urf/:multi?/:champion-urf",
  CHAMPION_PROFILE_BUILD_PICK_URF: "/lol/champions/urf/:multi?/:champion-urf",
  CHAMPION_PROFILE_BUILD_ARURF: "/lol/champions/arurf/:multi?/:champion-arurf",
  CHAMPION_PROFILE_BUILD_NEXUS_BLITZ: "/lol/champions/nexus-blitz/:multi?/:champion-nexus-blitz",
  CHAMPION_PROFILE_BUILD_ONE_FOR_ALL: "/lol/champions/one-for-all/:multi?/:champion-one-for-all",
  CHAMPION_PROFILE_BUILD_ARENA: "/lol/champions/arena/:multi?/:champion-arena-build",
  CHAMPION_PROFILE_MATCHUPS: "/lol/champions/:champion/matchups",
  CHAMPION_PROFILE_COUNTERS: "/lol/champions/:champion/counter",
  CHAMPION_PROFILE_ITEM_PATHS: "/lol/champions/:champion/item-paths",
  CHAMPION_PROFILE_ITEMS: "/lol/champions/:champion/items",
  CHAMPION_PROFILE_RUNE_SETS: "/lol/champions/:champion/rune-sets",
  CHAMPION_PROFILE_RUNES_TABLE: "/lol/champions/:champion/runes-table",
  CHAMPION_PROFILE_SPELLS_ABILITIES: "/lol/champions/:champion/spells-abilities",
  CHAMPION_PROFILE_DUOS: "/lol/champions/:champion/duos",

  UGG_APP_SPLASH: "/app",
  ABOUT: "/about", // page removed
  FAQ: "/faq",
  PRIVACY_POLICY: "/privacy-policy",
  PRIVACY_SHIELD: "/privacy-shield",
  CAREERS: "/careers",
  TOS: "/terms-of-service",
  ADBLOCK_WHITELIST: "/adblock-whitelist",

  // Marvel Rivals Promo
  MARVEL_RIVALS: "/marvel-rivals",

  // Electron only routes
  ELECTRON: {
    // App only routes
    FIRST_TIME: "/first-time",
    PROFILE_NOT_FOUND: "/profile-not-found",
    LIVE_GAME: "/live-game",
    // Patch Notes
    PATCH_NOTES: "/patch-notes",
    // FAQ
    FAQ: "/faq",
    HASH: {
      CHAMP_SELECT: "champ-select",
    },
  },
} as const;

export const RouterPaths = {
  PROFILE: AppRoutes.PROFILE_OVERVIEW,
  LIVE_GAME: AppRoutes.PROFILE_LIVE_GAME,
  LEADERBOARD: AppRoutes.LEADERBOARD,
  CHAMPION: [
    AppRoutes.CHAMPION_HOME,
    AppRoutes.CHAMPION_PROFILE_BUILD,
    AppRoutes.CHAMPION_PROFILE_BUILD_ARAM,
    AppRoutes.CHAMPION_PROFILE_BUILD_URF,
    AppRoutes.CHAMPION_PROFILE_BUILD_PICK_URF,
    AppRoutes.CHAMPION_PROFILE_BUILD_ARURF,
    AppRoutes.CHAMPION_PROFILE_BUILD_NEXUS_BLITZ,
    AppRoutes.CHAMPION_PROFILE_BUILD_ONE_FOR_ALL,
    AppRoutes.CHAMPION_PROFILE_BUILD_ARENA,
    AppRoutes.CHAMPION_PROFILE_MATCHUPS,
    AppRoutes.CHAMPION_PROFILE_COUNTERS,
    AppRoutes.CHAMPION_PROFILE_ITEM_PATHS,
    AppRoutes.CHAMPION_PROFILE_ITEMS,
    AppRoutes.CHAMPION_PROFILE_RUNE_SETS,
    AppRoutes.CHAMPION_PROFILE_RUNES_TABLE,
    AppRoutes.CHAMPION_PROFILE_SPELLS_ABILITIES,
    AppRoutes.CHAMPION_PROFILE_DUOS,
  ],
  TIER_LIST: [
    AppRoutes.DEFAULT_TIER_LIST,
    AppRoutes.TOP_TIER_LIST,
    AppRoutes.JUNGLE_TIER_LIST,
    AppRoutes.MID_TIER_LIST,
    AppRoutes.ADC_TIER_LIST,
    AppRoutes.SUPPORT_TIER_LIST,
    AppRoutes.DUO_TIER_LIST,
    AppRoutes.COMBAT_TIER_LIST,
    AppRoutes.OBJECTIVES_TIER_LIST,
    AppRoutes.SPECIAL_GAME_MODE_TIER_LIST,
    AppRoutes.ARAM_TIER_LIST,
    AppRoutes.PICK_URF_TIER_LIST,
    AppRoutes.ARURF_TIER_LIST,
    AppRoutes.NEXUS_BLITZ_TIER_LIST,
    AppRoutes.ONE_FOR_ALL_TIER_LIST,
    AppRoutes.ARENA_TIER_LIST,
    AppRoutes.ARENA_DUO_TIER_LIST,
  ],
  PATCH_NOTES: AppRoutes.ELECTRON.PATCH_NOTES,
} as const;

export const AdRoutes = {
  AD_FREE: [
    AppRoutes.HOME,
    AppRoutes.ELECTRON.PROFILE_NOT_FOUND,
    AppRoutes.ELECTRON.FIRST_TIME,
    AppRoutes.PROFILE_LOADING_MATCH_PAGE,
    AppRoutes.PROFILE_MATCH_PAGE_FORCE,
    AppRoutes.PRIVACY_POLICY,
    AppRoutes.FAQ,
  ],
  STACKED_ADS: [
    ...RouterPaths.TIER_LIST,
    RouterPaths.LEADERBOARD,
    AppRoutes.CHAMPION_HOME,
    AppRoutes.PROFILE_OVERVIEW,
    AppRoutes.PROFILE_CHAMPION_STATS,
    AppRoutes.PROFILE_HIGHLIGHTS,
    AppRoutes.ITEMS_DIRECTORY,
    AppRoutes.ITEM_WIKI,
  ],
} as const;
/***************************
 * Summoner Profiles
 ***************************/
export function getOldProfileOverviewUrl(region: string, summonerName: string) {
  const toPath = compile(AppRoutes.PROFILE_ROOT_OLD, { encode: encodeURIComponent, validate: false });
  try {
    return toPath({
      region: convertLeagueClientRegion(region.toLowerCase()),
      summonerName: summonerName.toLowerCase().trim(),
    });
  } catch (err) {
    console.error("getOldProfileOverviewUrl", arguments.length, ...arguments);
    console.error(err);
    return "";
  }
}

export function getProfileOverviewUrl(region: string, riotUserName: string, riotTagLine: string) {
  const toPath = compile(AppRoutes.PROFILE_OVERVIEW, { encode: encodeURIComponent, validate: false });
  try {
    return toPath({
      region: convertLeagueClientRegion(region.toLowerCase()),
      riotUserName: riotUserName.toLowerCase().trim().trim(),
      riotTagLine: riotTagLine.toLowerCase(),
    });
  } catch (err) {
    console.error("getProfileOverviewUrl", arguments.length, ...arguments);
    console.error(err);
    return "";
  }
}

export function getProfileChampionStatsUrl(region: string, riotUserName: string, riotTagLine: string) {
  const toPath = compile(AppRoutes.PROFILE_CHAMPION_STATS, { encode: encodeURIComponent, validate: false });
  return toPath({
    region: convertLeagueClientRegion(region.toLowerCase()),
    riotUserName: riotUserName.toLowerCase().trim(),
    riotTagLine: riotTagLine.toLowerCase(),
  });
}

export function getProfileLiveGameUrl(region: string, riotUserName: string, riotTagLine: string) {
  const toPath = compile(AppRoutes.PROFILE_LIVE_GAME, { encode: encodeURIComponent, validate: false });
  return toPath({
    region: convertLeagueClientRegion(region.toLowerCase()),
    riotUserName: riotUserName.toLowerCase().trim(),
    riotTagLine: riotTagLine.toLowerCase(),
  });
}

export function getProfileLoaderUrl(region: string, riotUserName: string, riotTagLine: string) {
  const toPath = compile(AppRoutes.PROFILE_LOADER, { encode: encodeURIComponent, validate: false });
  return toPath({
    region: convertLeagueClientRegion(region.toLowerCase()),
    riotUserName: riotUserName.toLowerCase().trim(),
    riotTagLine: riotTagLine.toLowerCase(),
  });
}

export function getProfileMatchLoaderUrl(
  region: string,
  riotUserName: string,
  riotTagLine: string,
  patch: string,
  matchId: string | number,
) {
  const toPath = compile(AppRoutes.PROFILE_LOADING_MATCH_PAGE, { encode: encodeURIComponent, validate: false });
  return toPath({
    region: convertLeagueClientRegion(region.toLowerCase()),
    riotUserName: riotUserName.toLowerCase().trim(),
    riotTagLine: riotTagLine.toLowerCase(),
    patch,
    matchId,
  });
}

export function getProfileMatchUrl(
  region: string,
  riotUserName: string,
  riotTagLine: string,
  patch: string,
  matchId: string | number,
) {
  const toPath = compile(AppRoutes.PROFILE_MATCH_PAGE, { encode: encodeURIComponent, validate: false });
  return toPath({
    region: convertLeagueClientRegion(region.toLowerCase()),
    riotUserName: riotUserName.toLowerCase().trim(),
    riotTagLine: riotTagLine.toLowerCase(),
    patch,
    matchId,
  });
}

export function getProfileForceMatchUrl(
  region: string,
  riotUserName: string,
  riotTagLine: string,
  patch: string,
  matchId: string | number,
) {
  const toPath = compile(AppRoutes.PROFILE_MATCH_PAGE_FORCE, { encode: encodeURIComponent, validate: false });
  return toPath({
    region: convertLeagueClientRegion(region.toLowerCase()),
    riotUserName: riotUserName.toLowerCase().trim(),
    riotTagLine: riotTagLine.toLowerCase(),
    patch,
    matchId,
  });
}

export function getProfileHighlightsUrl(region: string, riotUserName: string, riotTagLine: string) {
  const toPath = compile(AppRoutes.PROFILE_HIGHLIGHTS, { encode: encodeURIComponent, validate: false });
  return toPath({
    region: convertLeagueClientRegion(region.toLowerCase()),
    riotUserName: riotUserName.toLowerCase().trim(),
    riotTagLine: riotTagLine.toLowerCase(),
  });
}

/***************************
 * Champions
 ***************************/
interface ChampionBuildParams {
  language?: string;
  multi?: string;
  role?: string;
}
export function getChampionBuildUrl(champion: string, params?: ChampionBuildParams) {
  const { language, multi, role } = params || {};
  const finalLanguage = language === "en_US" ? null : language?.toLowerCase();

  const validRoles = [RoleS.TOP, RoleS.JUNGLE, RoleS.MIDDLE, RoleS.BOTTOM, RoleS.SUPPORT];
  const finalRole = role && validRoles.includes(normalizeRole(role) as RoleS) ? role : undefined;
  const toPath = compile(AppRoutes.CHAMPION_PROFILE_BUILD, { encode: encodeURIComponent, validate: false });
  return toPath({ champion, multi, role: finalRole, language: finalLanguage });
}

export interface SpecialGameModeChampionBuildParams {
  language?: string;
  multi?: string;
}
export function getSpecialGameModeChampionBuildUrl(
  champion: string,
  gameMode: string,
  params?: SpecialGameModeChampionBuildParams,
) {
  const { language, multi } = params || {};
  const gameMode2 = gameMode === "arena" ? "arena-build" : gameMode;
  const finalLanguage = language === "en_US" ? null : language?.toLowerCase();
  const toPath = compile(AppRoutes.CHAMPION_PROFILE_BUILD_SPECIAL_GAME_MODE, { encode: encodeURIComponent, validate: false });
  return toPath({ champion, gameMode, multi, gameMode2, language: finalLanguage });
}

export function getChampionCountersUrl(champion: string) {
  const toPath = compile(AppRoutes.CHAMPION_PROFILE_COUNTERS, { encode: encodeURIComponent, validate: false });
  return toPath({ champion });
}

export function getChampionMatchupsUrl(champion: string) {
  const toPath = compile(AppRoutes.CHAMPION_PROFILE_MATCHUPS, { encode: encodeURIComponent, validate: false });
  return toPath({ champion });
}

export function getChampionItemsUrl(champion: string) {
  const toPath = compile(AppRoutes.CHAMPION_PROFILE_ITEMS, { encode: encodeURIComponent, validate: false });
  return toPath({ champion });
}

export function getChampionItemPathsUrl(champion: string) {
  const toPath = compile(AppRoutes.CHAMPION_PROFILE_ITEM_PATHS, { encode: encodeURIComponent, validate: false });
  return toPath({ champion });
}
export function getChampionRunesTableUrl(champion: string) {
  const toPath = compile(AppRoutes.CHAMPION_PROFILE_RUNES_TABLE, { encode: encodeURIComponent, validate: false });
  return toPath({ champion });
}
export function getChampionRuneSetsUrl(champion: string) {
  const toPath = compile(AppRoutes.CHAMPION_PROFILE_RUNE_SETS, { encode: encodeURIComponent, validate: false });
  return toPath({ champion });
}

export function getChampionSpellsUrl(champion: string) {
  const toPath = compile(AppRoutes.CHAMPION_PROFILE_SPELLS_ABILITIES, { encode: encodeURIComponent, validate: false });
  return toPath({ champion });
}

export function getChampionDuosUrl(champion: string) {
  const toPath = compile(AppRoutes.CHAMPION_PROFILE_DUOS, { encode: encodeURIComponent, validate: false });
  return toPath({ champion });
}

/***************************
 * Leaderboards
 ***************************/
export function getLeaderboardsUrl(region: string, riotUserName?: string, riotTagLine?: string) {
  const params: { region: string; user?: string } = {
    region: convertLeagueClientRegion(region),
  };
  if (riotUserName && riotTagLine) {
    params.user = `${riotUserName}#${riotTagLine}`;
  }
  return `${AppRoutes.LEADERBOARD_RANKING}?${buildQueryParams(params)}`;
}

export function getChampionLeaderboard(champion?: string, region?: string) {
  const toPath = compile(AppRoutes.CHAMPION_LEADERBOARD, { encode: encodeURIComponent, validate: false });
  const validRegion = convertLeagueClientRegion(region || "", RegionS.WORLD);
  const params: { region?: string } = {};
  if (validRegion !== RegionS.WORLD) {
    params.region = validRegion;
  }
  const search = buildQueryParams(params);
  return `${toPath({ champion })}${search ? "?" + search : ""}`;
}

/***************************
 * Other
 ***************************/
export function getChampSelectUrl(location: { pathname: string; search: string }) {
  return `${location.pathname}${location.search}#${AppRoutes.ELECTRON.HASH.CHAMP_SELECT}`;
}
